import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const res_tsDay = `
	id
	createdAt
	updatedAt
	userProjectId
	date
	strTime
	endTime
	actualEndTime
	lunchStrTime
	lunchEndTime
	lunch
	hotel
	useDinner
	useAbroad
	teleworking
	totMin
	validated
	timesheetId
	laValidatedId
	laValidatedWhen
	lbValidatedId
	lbValidatedWhen
	comment
	stageDayUsed
	dayNumber
	salaryBase
	salary
	salaryOvertime
	salaryHourNight
	salaryHourTooEarly
	totMinOvertime
	leftHomeAt
	returnedHomeAt
	totMinNight
	totMinTooEarly
	dayType
	dayRate
	kgCoTwo
	totMinOvertime1
	totMinOvertime2
	salaryOvertime1
	salaryOvertime2
	transportTimePaid
	salaryTransport
	carAllowance
	productionFeeAllowance
	computerAllowance
	phoneAllowance
	boxKitAllowance
	totAllowances
	totPerDiem
	lunchPerDiem
	hotelPerDiem
	dinnerPerDiem
	abroadPerDiem
	notSpecifiedLunch
	useMileageToSet
	mileageToSetAllowance
	transportFromHomeToWork
	hours
	minutes
	hoursOvertime
	minutesOvertime
	hoursOvertime1
	minutesOvertime1
	hoursOvertime2
	minutesOvertime2
	hoursTransportTimePaid
	minutesTransportTimePaid
	hoursNight
	minutesNight
	hoursTooEarly
	minutesTooEarly
	strEndNotSpecified
	location {
	  id
	}
	stageDay {
		id
		createdAt
		updatedAt
		projectId
		date
		strTime
		endTime
		actualEndTime
		lunchStrTime
		lunchEndTime
		totMin
		comment
		useHours
		useMileageToSet
		kmToSet
		kmToSetSalary
		kmToSetDescription
		hoursDefined
		kmToSetDefined
		hours
		minutes
	}
	user {
		id
		name
    	firstName
	}
	encoderId
      encoder {
        id
        name
        fullName
        firstName
      }
	contract {
		useDinnerPerDiem
		useAbroadPerDiem
		useLunchPerDiem
		useHotelPerDiem
		useCarAllowance
		useProductionFeeAllowance
		useComputerAllowance
		usePhoneAllowance
		useBoxKitAllowance
		hotelPerDiem
		dinnerPerDiem
		carAllowanceRate
		productionFeeAllowanceRate
		computerAllowanceRate
		phoneAllowanceRate
		boxKitAllowanceRate
		abroadPerDiem
		lunchPerDiem
	}
	timesheet {
		id
		strDate
		endDate
		week
		totMin
		validated
		comment
		salary
		totMinOvertime
		totMinNight
		totMinTooEarly
		kgCoTwo
		totMinOvertime1
		totMinOvertime2
		transportTimePaid
		hasNotSubmittedDay
		weeklyTotalOvertime
		weeklyTotalOvertimeStr
		weeklyTotMinOvt
		weeklyTotMinOvt1
		weeklyTotMinOvt2
		weeklySalaryOvt
		weeklySalaryOvt1
		weeklySalaryOvt2
		weeklyCarAllowance
		weeklyComputerAllowance
		weeklyProductionFeeAllowance
		weeklyPhoneAllowance
		weeklyBoxKitAllowance
		dailyTotAllowances
		weeklyTotAllowances
		weeklyTotPerDiem
		hours
		minutes
		hoursOvertime
		minutesOvertime
		hoursOvertime1
		hoursOvertime2
		minutesOvertime1
		minutesOvertime2
		hoursTransportTimePaid
		minutesTransportTimePaid
		hoursNight
		minutesNight
		hoursTooEarly
		minutesTooEarly
		department
		departmentName
		function
		functionName
		weeklyTotMinOvtStr
		weeklyTotMinOvt1Str
		weeklyTotMinOvt2Str
		weeklyLunchPerDiem
		weeklyHotelPerDiem
		weeklyDinnerPerDiem
		weeklyAbroadPerDiem
	}
`;

const userTsDayDetail = `
	id
	createdAt
	updatedAt
	date
	fullName
	departmentName
	functionName
	status
	managerSalaryAcess {
		id
		managerID
		salaryType
		projectId
		disable
	}
	tsDays {
		id
		strTime
		endTime
		salary
		dayType
		lunch
		lunchStrTime
		lunchEndTime
		dinnerPerDiem
		totPerDiem
		hoursNight
		totMinNight
		salaryHourNight
		lunchPerDiem
		dayRate
		date
		actualEndTime
		hotel
		salaryBase
		salaryOvertime
		salaryOvertime1
		salaryOvertime2
		salaryTransport
		notSpecifiedLunch
		salaryHourTooEarly
		transportTimePaid
		transportFromHomeToWork
		hoursTransportTimePaid
		minutesTransportTimePaid
		carAllowance
		dayType
		comment
		hoursOvertime
		hoursOvertime1
		hoursOvertime2
		hours
		minutes
		hoursNight
		minutesNight
		hoursTooEarly
		minutesTooEarly
		minutesOvertime
		minutesOvertime1
		minutesOvertime2
		hoursTransportTimePaid
		teleworking
		kgCoTwo
		productionFeeAllowance
		totAllowances
		carAllowance
		phoneAllowance
		boxKitAllowance
		computerAllowance
		mileageToSetAllowance
		productionFeeAllowance
		useDinner
		useAbroad
		abroadPerDiem
		hotelPerDiem
		useMileageToSet
		leftHomeAt
		returnedHomeAt
		stageDayUsed
		validated
		askToDelete
		askToDeletes
		internalStatus
		completionStatus
		hasChangesAfterPay
		hasAskToDeleteInDetails
		user {
			id
			name
			firstName
			fullName
			functionName
		}
		encoderId
		encoder {
			id
			name
			firstName
			fullName
		}
		contract {
			useDinnerPerDiem
			useAbroadPerDiem
			useLunchPerDiem
			useHotelPerDiem
			useCarAllowance
			useProductionFeeAllowance
			useComputerAllowance
			usePhoneAllowance
			useBoxKitAllowance
			hotelPerDiem
			dinnerPerDiem
			carAllowanceRate
			productionFeeAllowanceRate
			computerAllowanceRate
			phoneAllowanceRate
			boxKitAllowanceRate
			abroadPerDiem
			lunchPerDiem
		}
		location {
			id
			setName
		}
		tsDayDetails {
			id
			createdAt
			updatedAt
			tsDayId
			type
			flagId
			value
			typeOne
			flagIdOne
			valueOne
			manualEntry
			amount
			totMin
			category
			payCode
			quantity
			costCenter
			projectPhase
			typeOne
			flagIdOne
			valueOne
			hideSalary
			askToDelete
			hasChangesAfterPay
			projectFlagItem {
				id
				createdAt
				updatedAt
				projectId
				flagId
				value
				name
				short
				factor
				amount
				payCode
				forScreen
				fieldType
				defaultValue
				jsFormula
				color
				bookmark
				category
				digitalSubmission
				disabled
				allowanceRate
				costCenter
				groupingCategory
				type
			}
			activity {
				id
				createdAt
				updatedAt
				projectId
				flagId
				value
				name
				short
				factor
				amount
				payCode
				forScreen
				fieldType
				defaultValue
				jsFormula
				color
				bookmark
				category
				digitalSubmission
				disabled
				allowanceRate
				costCenter
				groupingCategory
				type
			}
			location {
				id
				type
				status
				surface
				setName
			}
		}
	}
	tsDaysTotal {
		id
		createdAt
		updatedAt
		type
		amount
		hideSalary
		totalByCostCenter {
			id
			amount
			costCenterActivity
			color
			projectPhase
			costCenter
		}
	}
`;

export const getUsersActionsTimesheetEfc = async ({ myProject }, projectId = 0) => {
	try {
		let variables = { myProject };
		if (parseInt(projectId, 10) > 0) {
			variables = { projectId: parseInt(projectId, 10) };
		}
		const {
			data: { Users }
		} = await apollo.query({
			query: gql`
				query ($myProject: Boolean, $projectId: ID) {
					Users(myProject: $myProject, projectID: $projectId) {
						id
						name
						firstName
						department
						canAccessBudget
						canAccessConfig
						canAccessGreenReporting
						canAccessMyBudget
						canAccessOnboarding
						canAccessUsers
						canAssign
						canEditExpense
						canEditPo
						canValidateI9
						color
						department
						departmentName
						email
						firstName
						fullName
						function
						functionName
						gateKeeper
						picture
						phone
						email
						isProd
						isAdmin
						isDev
						isTranslator
						validationLevel
						reference
						upm
						viewOnBoardingScreen
						productionAccountant
						lineProducer
						studio
						address {
							id
						}
						validations {
							id
						}
						timeSheet {
							id
							strDate
							endDate
							weeklyTotalOvertimeStr
							transportTimePaid
							dailyTotAllowances
							hoursNight
							hours
							hoursOvertime
							hoursTransportTimePaid
							weeklyTotPerDiem
							dailyTotAllowances
							weeklyHotelPerDiem
							weeklyDinnerPerDiem
							weeklyAbroadPerDiem
							kgCoTwo
							salary
						}
						expenses {
							id
							amount
							date
							type
							department
						}
					}
				}
			`,
			variables,
			fetchPolicy: 'no-cache'
		});

		return Users;
	} catch (e) {
		console.log({ e });
	}
};

export const getProjectUserTsDays = async (Filter = {}) => {
	const {
		data: { GetProjectUserTsDays }
	} = await apollo.query({
		query: gql`
			query ($Filter: AdvancedGlobalFilterInput) {
				GetProjectUserTsDays(Filter: $Filter) {
					id
					createdAt
					updatedAt
					date
					fullName
					departmentName
					functionName
					status
					tsDay {
            ${res_tsDay}
					}
				}
			}
		`,
		variables: {
			Filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectUserTsDays;
};

export const getProjectUserCellTsDays = async (TsdayIds) => {
	const {
		data: { GetUsersTsdayDetails }
	} = await apollo.query({
		query: gql`
			query ($TsdayIds: [ID!]!) {
				GetUsersTsdayDetails(TsdayIds: $TsdayIds) {
					${userTsDayDetail}
				}
			}
		`,
		variables: {
			TsdayIds
		},
		fetchPolicy: 'no-cache'
	});

	return GetUsersTsdayDetails;
};

export const getPagesData = async (Filter = {}) => {
	const {
		data: { GetProjectUserTsDays }
	} = await apollo.query({
		query: gql`
			query ($Filter: AdvancedGlobalFilterInput) {
				GetProjectUserTsDays {
					id
					createdAt
					updatedAt
					date
					fullName
					departmentName
					functionName
					status
					tsDay {
            ${res_tsDay}
					}
				}
			}
		`,
		variables: {
			Filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectUserTsDays;
};

export const getProjectUserSalariesTimesheetsEfc = async (Filter = {}) => {
	const {
		data: { GetProjectUserSalaries }
	} = await apollo.query({
		query: gql`
			query ($Filter: AdvancedGlobalFilterInput) {
				GetProjectUserSalaries(Filter: $Filter) {
					id
					createdAt
					updatedAt
					startDate
					endDate
					fullName
					departmentValue
					departmentName
					functionName
					category
					categoryName
					categoryCostCenter
					hours
					salary
					co2
					hoursStr
					status
					timesheet {
						id
						createdAt
						updatedAt
						userProjectId
						encoderId
						strDate
						endDate
						week
						totMin
						validated
						comment
						salary
						totMinOvertime
						totMinNight
						totMinTooEarly
						kgCoTwo
						totMinOvertime1
						totMinOvertime2
						transportTimePaid
						hasNotSubmittedDay
						weeklyTotMinOvt
						weeklyTotMinOvt1
						weeklyTotMinOvt2
						weeklySalaryOvt
						weeklySalaryOvt1
						weeklySalaryOvt2
						weeklyCarAllowance
						weeklyProductionFeeAllowance
						weeklyComputerAllowance
						weeklyPhoneAllowance
						weeklyBoxKitAllowance
						weeklyMileageToSetAllowance
						dailyTotAllowances
						weeklyTotAllowances
						weeklyTotPerDiem
						weeklyLunchPerDiem
						weeklyHotelPerDiem
						weeklyDinnerPerDiem
						weeklyAbroadPerDiem
						weeklyTotalOvertime
						hours
						minutes
						hoursOvertime
						minutesOvertime
						hoursOvertime1
						minutesOvertime1
						hoursOvertime2
						minutesOvertime2
						hoursTransportTimePaid
						minutesTransportTimePaid
						hoursNight
						minutesNight
						hoursTooEarly
						minutesTooEarly
						user {
							id
							name
							firstName
							fullName
							functionName
						}
						encoderId
						encoder {
							id
							name
							fullName
							firstName
						}
						department
						departmentName
						function
						functionName
						contract {
							id
						}
						budgetDetail {
							id
						}
						weeklyTotMinOvtStr
						weeklyTotMinOvt1Str
						weeklyTotMinOvt2Str
						weeklyTotalOvertimeStr
					}
				}
			}
		`,
		variables: {
			Filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectUserSalaries;
};

export const delTsdayDetails = async (TsdayDetailsId, isBoolean) => {
	try {
		let result = await apollo
			.mutate({
				mutation: gql`
					mutation ($TsdayDetailsId: ID!, $RejectAskToDelete: Boolean) {
						DelTsdayDetails(TsdayDetailsId: $TsdayDetailsId, RejectAskToDelete: $RejectAskToDelete)
					}
				`,
				variables: {
					TsdayDetailsId,
					RejectAskToDelete: isBoolean
				}
			})
			.then((res) => {
				return true;
			})
			.catch((e) => {
				return false;
			});

		return result;
	} catch (e) {
		return {
			message: e
		};
	}
};

export const deleteEntry = async (TsdayIds, Undo = false) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($TsdayIds: [ID!]!, $Undo: Boolean) {
				DelTsdays(TsdayIds: $TsdayIds, Undo: $Undo)
			}
		`,
		variables: {
			TsdayIds: TsdayIds,
			Undo: Undo
		},
		fetchPolicy: 'no-cache'
	});

	return data.DelTsDays;
};

export const getProjectOnlyUserTsDay = async (Filter = {}) => {
	const {
		data: { GetProjectUserTsDays }
	} = await apollo.query({
		query: gql`
			query ($Filter: AdvancedGlobalFilterInput) {
				GetProjectUserTsDays(Filter: $Filter) {
					id
					createdAt
					updatedAt
					date
					fullName
					departmentName
					functionName
					status
					tsDay {
						${res_tsDay}
					}
				}
			}
		`,
		variables: {
			Filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectUserTsDays;
};

export const getProjectOnlyUserTimesheets = async (Filter = {}) => {
	const {
		data: { GetProjectUserSalaries }
	} = await apollo.query({
		query: gql`
			query ($Filter: AdvancedGlobalFilterInput) {
				GetProjectUserSalaries(Filter: $Filter) {
					id
					createdAt
					updatedAt
					startDate
					endDate
					fullName
					departmentValue
					departmentName
					functionName
					category
					categoryName
					categoryCostCenter
					hours
					salary
					co2
					hoursStr
					status
					timesheet {
						id
						user {
							id
						}
						encoderId
						encoder {
							id
							name
							fullName
							firstName
						}
					}
				}
			}
		`,
		variables: {
			Filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectUserSalaries;
};

export const saveTsDay = async (
	Date,
	StrTime,
	EndTime,
	LunchStrTime,
	LunchEndTime,
	Lunch,
	Hotel,
	UseDinner,
	UseAbroad,
	DayType,
	LeftHomeAt,
	ReturnedHomeAt,
	NotSpecifiedLunch,
	UseMileageToSet,
	TransportFromHomeToWork,
	UserId
) => {
	try {
		const MUTATION_SAVE_TS_DAY = gql`
			mutation (
				$Date: String!
				$StrTime: String!
				$EndTime: String
				$LunchStrTime: String
				$LunchEndTime: String
				$Lunch: Boolean
				$Hotel: Boolean
				$UseDinner: Boolean
				$UseAbroad: Boolean
				$DayType: Int
				$LeftHomeAt: String
				$ReturnedHomeAt: String
				$NotSpecifiedLunch: Boolean
				$UseMileageToSet: Boolean
        		$TransportFromHomeToWork: Boolean
				$UserId: ID
			) {
				AddUpdTsDay(
					Date: $Date
					StrTime: $StrTime
					EndTime: $EndTime
					LunchStrTime: $LunchStrTime
					LunchEndTime: $LunchEndTime
					Lunch: $Lunch
					Hotel: $Hotel
					UseDinner: $UseDinner
					UseAbroad: $UseAbroad
					DayType: $DayType
					LeftHomeAt: $LeftHomeAt
					ReturnedHomeAt: $ReturnedHomeAt
					NotSpecifiedLunch: $NotSpecifiedLunch
					UseMileageToSet: $UseMileageToSet
					TransportFromHomeToWork: $TransportFromHomeToWork
					UserId: $UserId
				) {
					${res_field}
				}
			}
		`;

		const {
			data: { AddUpdTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_SAVE_TS_DAY,
			variables: {
				Date,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				UserId
			}
		});

		return AddUpdTsDay;
	} catch (e) {
		console.log(e);
	}
};

export const submitTimeSheetsSlice = async (TimeSheets) => {
	try {
		const MUTATION_SUBMIT_SELECTED_TIMESHEET = gql`
			mutation SUBMIT_SELECTED_TIMESHEET($TimeSheets: [ID]!) {
				SubmitTimeSheetsSlice(TimeSheets: $TimeSheets)
			}
		`;

		const {
			data: { SubmitTimeSheetsSlice }
		} = await apollo.mutate({
			mutation: MUTATION_SUBMIT_SELECTED_TIMESHEET,
			variables: {
				TimeSheets: +TimeSheets
			}
		});

		return SubmitTimeSheetsSlice;
	} catch (e) {
		console.log(e);
	}
};

export const getProjectTotalTsdayDetails = async (Date) => {
	try {
		const {
			data: { GetProjectTotalTsdayDetails }
		} = await apollo.query({
			query: gql`
				query ($Date: String!) {
					GetProjectTotalTsdayDetails(Date: $Date) {
						id
						createdAt
						updatedAt
						type
						amount
						hideSalary
						totalByCostCenter {
							id
							amount
							costCenterActivity
							color
							projectPhase
							costCenter
						}
						# reimbursement
						# salary
						# prime
						# gross
						# primeTotalByCostCenter {
						# 	amount
						# 	costCenterActivity
						# 	color
						# 	projectPhase
						# 	costCenter
						# }
						# salaryTotalByCostCenter {
						# 	amount
						# 	costCenterActivity
						# 	color
						# 	projectPhase
						# 	costCenter
						# }
						# reimbursementTotalByCostCenter {
						# 	amount
						# 	costCenterActivity
						# 	color
						# 	projectPhase
						# 	costCenter
						# }
					}
				}
			`,
			variables: {
				Date
			},
			fetchPolicy: 'no-cache'
		});

		return GetProjectTotalTsdayDetails;
	} catch (e) {
		return e;
	}
};

export const getProjectUsersTsDaysPlanning = async (Filter = {}, FunctionValueDep = []) => {
	const {
		data: { GetProjectUsersTsDays }
	} = await apollo.query({
		query: gql`
			query ($Filter: AdvancedGlobalFilterInput, $FunctionValueDep: [FunctionValueDepInput!]) {
				GetProjectUsersTsDays(Filter: $Filter, FunctionValueDep: $FunctionValueDep) {
					${userTsDayDetail}
				}
			}
		`,
		variables: {
			Filter,
			FunctionValueDep
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectUsersTsDays;
};

export const getProjectUsersTsDaysPlanningDate = async () => {
	const {
		data: { GetDatesForTsd }
	} = await apollo.query({
		query: gql`
			query {
				GetDatesForTsd
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return GetDatesForTsd;
};

export const getTimesheetsToValidate = async () => {
	try {
		const {
			data: { GetTimesheetsToValidate }
		} = await apollo.query({
			query: gql`
				query {
					GetTimesheetsToValidate {
						id
						strDate
						endDate
						week
						totMin
						validated
						comment
						salary
						totMinOvertime
						totMinNight
						totMinTooEarly
						kgCoTwo
						totMinOvertime1
						totMinOvertime2
						transportTimePaid
						hasNotSubmittedDay
						weeklyTotalOvertime
						weeklyTotalOvertimeStr
						weeklyTotMinOvt
						weeklyTotMinOvt1
						weeklyTotMinOvt2
						weeklySalaryOvt
						weeklySalaryOvt1
						weeklySalaryOvt2
						weeklyCarAllowance
						weeklyComputerAllowance
						weeklyProductionFeeAllowance
						weeklyPhoneAllowance
						weeklyBoxKitAllowance
						dailyTotAllowances
						weeklyTotAllowances
						weeklyTotPerDiem
						hours
						minutes
						hoursOvertime
						minutesOvertime
						hoursOvertime1
						hoursOvertime2
						minutesOvertime1
						minutesOvertime2
						hoursTransportTimePaid
						minutesTransportTimePaid
						hoursNight
						minutesNight
						hoursTooEarly
						minutesTooEarly
						department
						departmentName
						function
						functionName
						weeklyTotMinOvtStr
						weeklyTotMinOvt1Str
						weeklyTotMinOvt2Str
						weeklyLunchPerDiem
						weeklyHotelPerDiem
						weeklyDinnerPerDiem
						weeklyAbroadPerDiem
						user {
							name
							firstName
							email
						}
						encoderId
						encoder {
							id
							name
							firstName
							fullName
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return GetTimesheetsToValidate;
	} catch (e) {
		console.log({ e });
	}
};

export const getTimesheetsDetailAuthToValidate = async (id) => {
	let TimesheetId = +id;
	try {
		const {
			data: { TimesheetDetails }
		} = await apollo.query({
			query: gql`
				query ($TimesheetId: Int!, $UnLoadNotSubmittedDay: Boolean) {
					TimesheetDetails(TimesheetId: $TimesheetId, UnLoadNotSubmittedDay: $UnLoadNotSubmittedDay) {
						id
						userProjectId
						date
						strTime
						endTime
						lunchStrTime
						lunchEndTime
						lunch
						hotel
						useDinner
						useAbroad
						teleworking
						totMin
						validated
						timesheetId
						comment
						stageDayUsed
						dayNumber
						salaryBase
						totPerDiem
						salary
						salaryOvertime
						salaryOvertime1
						salaryOvertime2
						salaryHourNight
						salaryHourTooEarly
						totMinOvertime
						leftHomeAt
						returnedHomeAt
						totMinNight
						totMinTooEarly
						dayType
						dayRate
						kgCoTwo
						totMinOvertime1
						totMinOvertime2
						transportTimePaid
						salaryTransport
						carAllowance
						productionFeeAllowance
						computerAllowance
						phoneAllowance
						boxKitAllowance
						totAllowances
						hours
						minutes
						hoursOvertime
						minutesOvertime1
						minutesOvertime
						minutesOvertime2
						hoursOvertime1
						hoursOvertime2
						hoursTransportTimePaid
						minutesTransportTimePaid
						hoursNight
						minutesNight
						minutesTooEarly
						hoursTooEarly
						mileageToSetAllowance
						timesheet {
							id
							strDate
							endDate
							week
							totMin
							validated
							comment
							salary
							totMinOvertime
							totMinNight
							totMinTooEarly
							kgCoTwo
							totMinOvertime1
							totMinOvertime2
							transportTimePaid
							hasNotSubmittedDay
							weeklyTotalOvertime
							weeklyTotalOvertimeStr
							weeklyTotMinOvt
							weeklyTotMinOvt1
							weeklyTotMinOvt2
							weeklySalaryOvt
							weeklySalaryOvt1
							weeklySalaryOvt2
							weeklyCarAllowance
							weeklyComputerAllowance
							weeklyProductionFeeAllowance
							weeklyPhoneAllowance
							weeklyBoxKitAllowance
							dailyTotAllowances
							weeklyTotAllowances
							weeklyTotPerDiem
							hours
							minutes
							hoursOvertime
							minutesOvertime
							hoursOvertime1
							hoursOvertime2
							minutesOvertime1
							minutesOvertime2
							hoursTransportTimePaid
							minutesTransportTimePaid
							hoursNight
							minutesNight
							hoursTooEarly
							minutesTooEarly
							department
							departmentName
							function
							functionName
							weeklyTotMinOvtStr
							weeklyTotMinOvt1Str
							weeklyTotMinOvt2Str
							weeklyLunchPerDiem
							weeklyHotelPerDiem
							weeklyDinnerPerDiem
							weeklyAbroadPerDiem
							encoderId
							encoder {
								id
								name
								firstName
								fullName
							}
						}
						user {
							id
							name
							firstName
						}
						encoderId
						encoder {
							id
							name
							firstName
							fullName
						}
						contract {
							useDinnerPerDiem
							useAbroadPerDiem
							useLunchPerDiem
							useHotelPerDiem
							useCarAllowance
							useProductionFeeAllowance
							useComputerAllowance
							usePhoneAllowance
							useBoxKitAllowance
							carAllowanceRate
							productionFeeAllowanceRate
							computerAllowanceRate
							phoneAllowanceRate
							boxKitAllowanceRate
							hotelPerDiem
							lunchPerDiem
							dinnerPerDiem
							abroadPerDiem
						}
					}
				}
			`,
			variables: {
				TimesheetId,
				UnLoadNotSubmittedDay: true
			},
			fetchPolicy: 'no-cache'
		});

		return TimesheetDetails;
	} catch (e) {
		console.log({ e });
	}
};

export const getPlanningStatus = async (date) => {
	try {
		const {
			data: { GetPlanningStatus }
		} = await apollo.query({
			query: gql`
				query ($Date: String!) {
					GetPlanningStatus(Date: $Date) {
						id
						projectId
						status
						date
						planningLevel
						finalizationDate
						isPlanningSentBefore
						isValid
						isFinalValid
						canSendToPay
						isManagerPresent
						previousPlanningStatus
						userValidationLevel
						userFullName
					}
				}
			`,
			variables: {
				Date: date
			},
			fetchPolicy: 'no-cache'
		});

		return GetPlanningStatus;
	} catch (e) {
		return e;
	}
};

export const validateTsDays = async (TsDayId = null, Date = null, FinalizationDate = null, Final = false, LockPlanning = false) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($TsDayId: ID!, $Date: String, $FinalizationDate: String, $Final: Boolean, $LockPlanning: Boolean) {
				ValidateTsDay(TsDayId: $TsDayId, Date: $Date, FinalizationDate: $FinalizationDate, Final: $Final, LockPlanning: $LockPlanning) {
					id
					strTime
					endTime
					salary
					dayType
					lunch
					lunchStrTime
					lunchEndTime
					dinnerPerDiem
					totPerDiem
					hoursNight
					totMinNight
					salaryHourNight
					lunchPerDiem
					dayRate
					date
					actualEndTime
					hotel
					salaryBase
					salaryOvertime
					salaryOvertime1
					salaryOvertime2
					salaryTransport
					notSpecifiedLunch
					salaryHourTooEarly
					transportTimePaid
					transportFromHomeToWork
					hoursTransportTimePaid
					minutesTransportTimePaid
					carAllowance
					dayType
					comment
					hoursOvertime
					hoursOvertime1
					hoursOvertime2
					hours
					minutes
					hoursNight
					minutesNight
					hoursTooEarly
					minutesTooEarly
					minutesOvertime
					minutesOvertime1
					minutesOvertime2
					hoursTransportTimePaid
					teleworking
					kgCoTwo
					productionFeeAllowance
					totAllowances
					carAllowance
					phoneAllowance
					boxKitAllowance
					computerAllowance
					mileageToSetAllowance
					productionFeeAllowance
					useDinner
					useAbroad
					abroadPerDiem
					hotelPerDiem
					useMileageToSet
					leftHomeAt
					returnedHomeAt
					stageDayUsed
					validated
					user {
						id
						name
						firstName
						fullName
						functionName
					}
					encoderId
					encoder {
						id
						name
						firstName
						fullName
					}
					contract {
						useDinnerPerDiem
						useAbroadPerDiem
						useLunchPerDiem
						useHotelPerDiem
						useCarAllowance
						useProductionFeeAllowance
						useComputerAllowance
						usePhoneAllowance
						useBoxKitAllowance
						hotelPerDiem
						dinnerPerDiem
						carAllowanceRate
						productionFeeAllowanceRate
						computerAllowanceRate
						phoneAllowanceRate
						boxKitAllowanceRate
						abroadPerDiem
						lunchPerDiem
					}
					tsDayDetails {
						id
						createdAt
						updatedAt
						tsDayId
						type
						flagId
						value
						typeOne
						flagIdOne
						valueOne
						manualEntry
						amount
						totMin
						category
						payCode
						costCenter
						typeOne
						flagIdOne
						valueOne
						projectFlagItem {
							id
							createdAt
							updatedAt
							projectId
							flagId
							value
							name
							short
							factor
							amount
							payCode
							forScreen
							fieldType
							defaultValue
							jsFormula
							color
							bookmark
							category
							digitalSubmission
							disabled
							allowanceRate
							costCenter
							type
						}
						activity {
							id
							createdAt
							updatedAt
							projectId
							flagId
							value
							name
							short
							factor
							amount
							payCode
							forScreen
							fieldType
							defaultValue
							jsFormula
							color
							bookmark
							category
							digitalSubmission
							disabled
							allowanceRate
							costCenter
							type
						}
					}
				}
			}
		`,
		variables: {
			TsDayId: +TsDayId,
			Date: Date,
			FinalizationDate: FinalizationDate,
			Final: Final,
			LockPlanning: LockPlanning
		},
		fetchPolicy: 'no-cache'
	});

	return data.ValidateTsDay;
};

export const rejectTsDay = async (TsDayId, Comment, ForPlanning = false) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($TsDayId: ID!, $Comment: String, $ForPlanning: Boolean) {
				RejectTsDay(TsDayId: $TsDayId, Comment: $Comment, ForPlanning: $ForPlanning) {
					id
				}
			}
		`,
		variables: {
			TsDayId: +TsDayId,
			Comment: Comment,
			ForPlanning: ForPlanning
		},
		fetchPolicy: 'no-cache'
	});

	return data.RejectTsDay;
};

export const rejectTsDayPlanning = async (TsDayIds, Comment, ForPlanning = false) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($TsDayIds: [ID!]!, $Comment: String, $ForPlanning: Boolean) {
				RejectTsDays(TsDayIds: $TsDayIds, Comment: $Comment, ForPlanning: $ForPlanning) {
					id
				}
			}
		`,
		variables: {
			TsDayIds,
			Comment,
			ForPlanning
		},
		fetchPolicy: 'no-cache'
	});

	return data.RejectTsDays;
};

export const undoRejectTsDay = async (TsDayId) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($TsDayId: ID!) {
				UndoRejectTsDay(TsDayId: $TsDayId) {
					id
				}
			}
		`,
		variables: {
			TsDayId: +TsDayId
		},
		fetchPolicy: 'no-cache'
	});

	return data.UndoRejectTsDay;
};

export const updateMultipleTsdayDetails = async (TsDayDetailsIds, TsDayDetailsInput) => {
	try {
		const mutation = gql`
			mutation ($TsDayDetailsIds: [ID!]!, $TsDayDetailsInput: TsDayDetailsInput!) {
				UpdateMultipleTsdayDetails(TsDayDetailsIds: $TsDayDetailsIds, TsDayDetailsInput: $TsDayDetailsInput)
			}
		`;
		const {
			data: { UpdateMultipleTsdayDetails }
		} = await apollo.mutate({
			mutation,
			variables: {
				TsDayDetailsIds,
				TsDayDetailsInput
			}
		});

		return UpdateMultipleTsdayDetails;
	} catch (e) {
		return e;
	}
};

export const addUpdTsDayDetails = async (TsdayDetailsId, TsdayIds, UpdTsDayDetailsInput) => {
	try {
		const mutation = gql`
			mutation ($TsdayDetailsId: [ID!]!, $TsdayIds: [ID!]!, $UpdTsDayDetailsInput: TsDayDetailsInput!) {
				AddUpdTsDayDetails(TsdayDetailsId: $TsdayDetailsId, TsdayIds: $TsdayIds, UpdTsDayDetailsInput: $UpdTsDayDetailsInput) {
					id
				}
			}
		`;
		const {
			data: { AddUpdTsDayDetails }
		} = await apollo.mutate({
			mutation,
			variables: {
				TsdayDetailsId,
				TsdayIds,
				UpdTsDayDetailsInput
			}
		});

		return AddUpdTsDayDetails;
	} catch (e) {
		return { message: e };
	}
};

export const updTsDayDetails = async (tsDayDetailsInput = [], validate = false) => {
	let tsdayId = null;
	if (tsDayDetailsInput[0] && tsDayDetailsInput[0].rowDelete !== undefined) {
		tsdayId = +tsDayDetailsInput[0].tsDayId;
		tsDayDetailsInput = [];
	} else {
		tsdayId = +tsDayDetailsInput[0].tsDayId;
	}

	try {
		const mutation = gql`
			mutation ($validate: Boolean!, $tsDayDetailsInput: [TsDayDetailsInput!]!, $tsdayId: ID!) {
				UpdTsDayDetails(Validate: $validate, TsdayDetailsInput: $tsDayDetailsInput, TsdayId: $tsdayId)
			}
		`;

		const {
			data: { UpdTsDayDetails }
		} = await apollo.mutate({
			mutation,
			variables: {
				validate,
				tsDayDetailsInput,
				tsdayId
			}
		});

		return UpdTsDayDetails;
	} catch (e) {
		return e;
	}
};

export const substituteUserTsDay = async (tsDayId, userId) => {
	try {
		const mutation = gql`
			mutation ($tsDayId: ID!, $userId: ID!) {
				SubstituteUserTsday(TsDayId: $tsDayId, UserId: $userId) {
					id
					date
					user {
						id
						name
					}
				}
			}
		`;

		const {
			data: { SubstituteUserTsday }
		} = await apollo.mutate({
			mutation,
			variables: {
				tsDayId: +tsDayId,
				userId: +userId
			}
		});

		return SubstituteUserTsday;
	} catch (e) {
		console.log({ 'SUBSTITUTE USER TS DAY ERROR': e });
	}
};

export const updateUserEfcRole = async (userId, regisseur, chargeDeProd, deleteUnsubmitedTsday = false) => {
	try {
		const mutation = gql`
			mutation ($userId: [ID!]!, $regisseur: Boolean, $chargeDeProd: Boolean, $deleteUnsubmitedTsday: Boolean) {
				UpdateUserRole(UserId: $userId, Regisseur: $regisseur, ChargeDeProd: $chargeDeProd, DeleteUnsubmitedTsday: $deleteUnsubmitedTsday)
			}
		`;

		const {
			data: { UpdateUserRole }
		} = await apollo.mutate({
			mutation,
			variables: {
				userId,
				regisseur,
				chargeDeProd,
				deleteUnsubmitedTsday
			},
			fetchPolicy: 'no-cache'
		});

		return UpdateUserRole;
	} catch (e) {
		console.log({ ERROR_UPDATE_USER_EFC_ROLE: e });
	}
};

export const addUpdManagerSalaryAccess = async (managerSalaryAccessInput) => {
	try {
		const mutation = gql`
			mutation ($ManagerSalaryAccessInput: [ManagerSalaryAccessInput!]!) {
				AddUpdManagerSalaryAccess(ManagerSalaryAccessInput: $ManagerSalaryAccessInput)
			}
		`;
		const {
			data: { ManagerSalaryAccessInput }
		} = await apollo.mutate({
			mutation,
			variables: {
				ManagerSalaryAccessInput: managerSalaryAccessInput
			},
			fetchPolicy: 'no-cache'
		});

		return ManagerSalaryAccessInput;
	} catch (e) {
		throw new e();
	}
};

export const getTotalDetailsTsdayUser = async (date, filter = {}) => {
	const {
		data: { GetProjectTotalTsdayDetailsAndUsers }
	} = await apollo.query({
		query: gql`
			query ($date: String!, $filter: AdvancedGlobalFilterInput) {
				GetProjectTotalTsdayDetailsAndUsers(Date: $date, Filter: $filter) {
					${userTsDayDetail}
				}
			}
		`,
		variables: {
			date,
			filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetProjectTotalTsdayDetailsAndUsers;
};

export const getLastChange = async (date) => {
	const {
		data: { GetPlanningLastChanged }
	} = await apollo.query({
		query: gql`
			query ($date: String!) {
				GetPlanningLastChanged(Date: $date)
			}
		`,
		variables: {
			date
		},
		fetchPolicy: 'no-cache'
	});

	return GetPlanningLastChanged;
};
